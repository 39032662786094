<template>
  <JenisVariableLaporanManajemenForm mode="Tambah" module="Jenis Variable Laporan Manajemen">
  </JenisVariableLaporanManajemenForm>
</template>

<script>
import JenisVariableLaporanManajemenForm from './form';

const JenisVariableLaporanManajemenAdd = {
  name: 'JenisVariableLaporanManajemenAdd',
  components: { JenisVariableLaporanManajemenForm },
};

export default JenisVariableLaporanManajemenAdd;
</script>
